var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('TitleHeader',{attrs:{"title":_vm.title}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"bottom_box position-fixed max-w-px-768 bottom-0 start-50 translate-middle-x w-100 p-3 bg-white"},[_c('div',{staticClass:"flex-between-center gap-2"},[_c('span',{staticClass:"w-100 py-2 px-3 btn bg-main text-white",on:{"click":function($event){return _vm.$alert('준비중 입니다.')}}},[_vm._v("상품권 교환")]),_c('router-link',{staticClass:"w-100 py-2 px-3 btn btn-outline-main",attrs:{"to":"/my-assets/user-list"}},[_vm._v("리워드 내역")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-4 px-4 mx-3 rounded-4 mb-3 bg-sub"},[_c('h5',{staticClass:"text-primary mb-2"},[_vm._v("현재 리워드 자산")]),_c('h1',[_vm._v("664,765 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금일 리워드 자산 등록")]),_c('h1',[_vm._v("31,000 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금일 리워드 지급")]),_c('h1',[_vm._v("76,000 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금월 누적 리워드 지급")]),_c('h1',[_vm._v("430,000 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금년 누적 리워드 지급")]),_c('h1',[_vm._v("920,765 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-pink"},[_c('h5',{staticClass:"text-pink mb-2"},[_vm._v("현 잔고 자산")]),_c('h1',[_vm._v("887,765 P")])])
}]

export { render, staticRenderFns }