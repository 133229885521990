<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub text-center">
            <h5 class="text-primary mb-2">관리유저/미션수행평가<br>
            <span class="fs-px-16 text-body">노래 265곡, 기타 485건 리워드 자산등록</span></h5>
        </div>

        <div class="fs-px-13 px-3">
            <div class="row border text-center">
                <div class="flex-center-center border-end col-1 p-0 "><small>순번</small></div>
                <div class="flex-center-center border-end col-3">id</div>
                <div class="flex-center-center border-end col-5 d-flex flex-column text-center px-0">
                    <div class="border-bottom w-100">금일 미션평가</div>
                    <div class="w-100 flex-between-center p-0">
                        <div class="flex-center-center w-50 border-end h-100">노래</div>
                        <div class="flex-center-center w-50">기타<br>미션</div>
                    </div>
                </div>
                <div class="col-3 flex-center-center">연락처</div>
            </div>
            <div class="row text-center" v-for="(item, index) in list" :key="index">
                <div class="border-end lh-px-30 border-start col-1 px-0 border-bottom"><small>{{index+1}}</small></div>
                <div class="border-end lh-px-30 col-3 px-0 border-bottom">{{item.id}}</div>
                <div class="border-end lh-px-30 col-5 border-bottom px-0"><div class="d-flex text-center h-100">
                    <div class="w-50 border-end lh-px-30" :class="{'bg-pink':item.song<20}">{{item.song}}</div>
                    <div class="w-50" :class="{'bg-pink':item.mission<30}">{{item.mission}}</div>
                </div></div>
                <div class="border-end lh-px-30 col-3 px-1 border-bottom">
                    <button class="btn btn-sm fs-px-13 text-body-i py-0 w-100" :style="`background-color:${item.contact.color}`"  @click="$alert('준비중 입니다.')"><small>{{item.contact.type}}</small></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산',
            list:[
                {
                    type:'song',
                    id:'smadkl',
                    song:15,
                    mission:90,
                    contact:{
                        type:'전화',
                        color: '#2869ff'
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:85,
                    mission:90,
                    contact:{
                        type:'카카오톡',
                        color: '#ffab28'
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:18,
                    mission:65,
                    contact:{
                        type:'Line',
                        color: '#2db10e'
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:95,
                    mission:22,
                    contact:{
                        type:'Insta DM',
                        color: '#c33dd9'
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:95,
                    mission:90,
                    contact:{
                        type:'Facebook',
                        color: '#0b1a8d'
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:55,
                    mission:30,
                    contact:{
                        type:'Linked in',
                        color: '#447933'
                    }
                },
            ]
        }
    }

}
</script>

<style scoped>
    .page{
        padding-bottom: 75px;
    }
</style>