<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub">
            <h5 class="text-primary mb-2">현재 충전 자산</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금일 리워드 자산 등록</h5>
            <h1>64,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금일 유저관리수수료 지급</h5>
            <h1>34,000 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금월 누적 유저관리수수료 지급</h5>
            <h1>300,000 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금년 누적 유저관리수수료 지급</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-pink">
           <h5 class="text-pink mb-2">현 잔고 자산</h5>
            <h1>326,765 P</h1>
        </div>
        <div class="bottom_box position-fixed max-w-px-768 bottom-0 start-0 w-100 p-3 bg-white">
            <div class="flex-between-center gap-2 mb-2">
                <router-link to="/my-assets/smc-list" class="w-100 py-2 px-3 btn bg-gray-200">My 유저</router-link>
                <!-- <router-link to="/my-assets/smc-charge" class="w-100 py-2 px-3 btn bg-gray-200">자산 충전소</router-link> -->
                <span class="w-100 py-2 px-3 btn bg-gray-200" @click="$alert('준비중 입니다.')">자산 충전소</span>
            </div>
            <div class="flex-between-center gap-2">
                <span @click="$alert('준비중 입니다.')" class="w-100 py-2 px-3 btn bg-gray-200">수수료지급신청</span>
                <span @click="$alert('준비중 입니다.')" class="w-100 py-2 px-3 btn bg-gray-200">쇼핑몰 이동</span>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산'
        }
    }

}
</script>

<style scoped>
    .page{
        padding-bottom: 124px;
    }
</style>